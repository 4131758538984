<template>
  <div class="edit-survey-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <div v-if="publishErrorMessage">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <div v-html="publishErrorMessage"></div>
            <button type="button" class="close" @click="hidePublishErrorMessage()" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <ValidationObserver ref="form" v-if="!isLoading" v-slot="{}">
          <CForm>
            <CCard class="edit-quiz-info-card">
              <CCardHeader>
                <CRow>
                  <CCol md="3" sm="12">
                    <router-link :to="surveyListUrl">
                      <CIcon name="cil-arrow-left" class="mr-1" />
                      {{ $t('EDIT_QUIZ_COMPONENT_BACK_LINK_BUTTON') }}
                    </router-link>
                  </CCol>
                  <CCol md="9" sm="12" class="text-right">
                    <CButton class="btn btn-publish mr-3" :disabled="isEnabledPublish" @click="publish()">
                      {{ $t('EDIT_QUIZ_COMPONENT_PUBLISH_BUTTON') }}
                    </CButton>
                    <CButton class="btn btn-save-as-draft" @click="saveAsDraft()">
                      <img src="@/assets/images/paper-clip.svg" alt="" />
                      {{ $t('EDIT_QUIZ_COMPONENT_SAVE_AS_DRAFT_BUTTON') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CCardHeader>

              <CCardBody v-if="isLoading">
                <vcl-list></vcl-list>
              </CCardBody>

              <CCardBody>
                <CRow>
                  <CCol lg="9" md="9">
                    <CRow>
                      <CCol lg="4" md="4">
                        <ValidationProvider name="EDIT_QUIZ_COMPONENT_START_DATE_FIELD" rules="required">
                          <div :class="[{ 'is-invalid': hasDateTimeError }, 'form-group']" slot-scope="{ errors }">
                            <label>{{ $t('EDIT_QUIZ_COMPONENT_START_DATE_LABEL') }}</label>
                            <date-picker
                              v-model="survey.start_date"
                              placeholder="YYYY-MM-DD HH:mm"
                              type="datetime"
                              format="YYYY-MM-DD HH:mm"
                              valuetype="format"
                            >
                            </date-picker>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                      <CCol lg="4" md="4">
                        <div>
                          <label>{{ $t('EDIT_QUIZ_COMPONENT_DURATION_LABEL') }}</label>
                          <div>
                            <span>{{ duration || $t('EDIT_QUIZ_COMPONENT_DURATION_TEXT') }}</span>
                          </div>
                        </div>
                      </CCol>

                      <CCol lg="4" md="4">
                        <ValidationProvider name="EDIT_QUIZ_COMPONENT_END_DATE_FIELD" rules="required">
                          <div :class="[{ 'is-invalid': hasDateTimeError }, 'form-group']" slot-scope="{ errors }">
                            <label>{{ $t('EDIT_QUIZ_COMPONENT_END_DATE_LABEL') }}</label>
                            <date-picker
                              v-model="survey.end_date"
                              placeholder="YYYY-MM-DD HH:mm"
                              type="datetime"
                              format="YYYY-MM-DD HH:mm"
                              valuetype="format"
                            >
                            </date-picker>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="4" md="4">
                        <ValidationProvider name="EDIT_SURVEY_COMPONENT_EN_TITLE_FIELD" rules="required|max:24">
                          <div slot-scope="{ errors }">
                            <label>{{ $t('EDIT_SURVEY_COMPONENT_EN_TITLE_LABEL') }}</label>
                            <div class="staking-limit-text-group">
                              <input
                                type="text"
                                v-model="survey.name"
                                :placeholder="$t('EDIT_SURVEY_COMPONENT_EN_TITLE_PLACEHOLDER')"
                                class="form-control"
                                :maxlength="maxOfEnName"
                              />
                              <div class="remain-num-of-charaters" v-text="'/' + (maxOfEnName - survey.name.length)"></div>
                            </div>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                      <CCol lg="4" md="4">
                        <ValidationProvider name="EDIT_SURVEY_COMPONENT_JP_TITLE_FIELD" rules="max:14">
                          <div slot-scope="{ errors }">
                            <label>{{ $t('EDIT_SURVEY_COMPONENT_JP_TITLE_LABEL') }}</label>
                            <div class="staking-limit-text-group">
                              <input
                                type="text"
                                v-model="survey.name_ja"
                                :placeholder="$t('EDIT_SURVEY_COMPONENT_JP_TITLE_PLACEHOLDER')"
                                class="form-control"
                                :maxlength="maxOfJpName"
                              />
                              <div class="remain-num-of-charaters" v-text="'/' + (maxOfJpName - survey.name_ja.length)"></div>
                            </div>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>

                      <CCol lg="4" md="4">
                        <div>
                          <label>{{ $t('EDIT_QUIZ_COMPONENT_NUM_OF_QUESTIONS_LABEL') }}</label>
                          <div class="form-control-plaintext">
                            <span>{{ survey.questions.length }}</span>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol lg="3" md="3">
                    <CRow class="form-paid-membership">
                      <CCol lg="12" md="12">
                        <table class="table table-price">
                          <thead>
                            <tr>
                              <th scope="col">{{ $t('EDIT_QUIZ_COMPONENT_MEMBERSHIP_NAME_COLUMN') }}</th>
                              <th scope="col">{{ $t('EDIT_QUIZ_COMPONENT_MEMBERSHIP_POINTS_COLUMN') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in membershipTypes" :key="index">
                              <td width="40%">
                                <div class="form-control-plaintext">
                                  <StakingBadge class="no-border" type="membership-type" :text="item.name" />
                                </div>
                              </td>
                              <td>
                                <ValidationProvider
                                  :name="$t(`EDIT_QUIZ_COMPONENT_POINTS_FIELD`, { name: item.name })"
                                  :rules="getMembershipPointsRules"
                                >
                                  <div class="form-group group-price" slot-scope="{ errors }" :key="index">
                                    <currency-input
                                      class="input-field form-control text-right"
                                      placeholder="-"
                                      v-model="item.points"
                                      :precision="0"
                                    />
                                    <span class="currency-name">{{ $t('EDIT_QUIZ_COMPONENT_MEMBERSHIP_POINTS_TEXT') }}</span>
                                    <span class="error-msg-wrap">
                                      <span class="error-msg">{{ errors[0] }}</span>
                                    </span>
                                  </div>
                                </ValidationProvider>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard class="question-card" v-for="(question, index) in survey.questions" :key="index">
              <CCardBody v-if="isLoading">
                <vcl-list></vcl-list>
              </CCardBody>
              <CCardBody>
                <div class="question-item">
                  <div class="question-item-header">
                    <div class="d-flex question-item-row">
                      <div class="p-2 bd-highlight question-item-col-1"></div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-2">
                        <h4>{{ $t(`EDIT_SURVEY_COMPONENT_EN_SECTION_TITLE`) }}</h4>
                      </div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-3">
                        <h4>{{ $t(`EDIT_SURVEY_COMPONENT_JP_SECTION_TITLE`) }}</h4>
                      </div>
                      <div class="p-2 bd-highlight question-item-col-4">
                        <CButton @click="removeQuestion(question, index)" class="btn btn-remove-question">
                          <img src="@/assets/images/ico-del-user.svg" alt class="hand edit-icon" />
                        </CButton>
                      </div>
                    </div>
                  </div>
                  <div class="question-item-body">
                    <div class="d-flex flex-wrap question-item-row">
                      <div class="p-2 bd-highlight question-item-col-1"></div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-2">
                        <ValidationProvider name="EDIT_SURVEY_COMPONENT_EN_QUESTION_TITLE_FIELD" :rules="getQuestionTitleRules">
                          <div slot-scope="{ errors }">
                            <label>{{ $t('EDIT_SURVEY_COMPONENT_EN_QUESTION_TITLE_LABEL') }}</label>
                            <input
                              type="text"
                              v-model="question.title"
                              :placeholder="$t('EDIT_SURVEY_COMPONENT_EN_QUESTION_TITLE_PLACEHOLDER')"
                              class="form-control"
                            />
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="p-2 bd-highlight question-item-col-center"></div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-3">
                        <ValidationProvider name="EDIT_SURVEY_COMPONENT_JP_QUESTION_TITLE_FIELD" rules="max:1000">
                          <div slot-scope="{ errors }">
                            <label>{{ $t('EDIT_SURVEY_COMPONENT_JP_QUESTION_TITLE_LABEL') }}</label>
                            <input
                              type="text"
                              v-model="question.title_ja"
                              :placeholder="$t('EDIT_SURVEY_COMPONENT_JP_QUESTION_TITLE_PLACEHOLDER')"
                              class="form-control"
                            />
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="p-2 bd-highlight question-item-col-4"></div>
                    </div>

                    <div class="d-flex question-item-row">
                      <div class="p-2 bd-highlight question-item-col-1"></div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-2">
                        <select
                          v-model="question.question_type"
                          class="question-type-select form-control"
                          @change="onChangeQuestionType(question, $event)"
                        >
                          <option v-for="item in questionTypeList" :key="item.value" :value="item.value">{{ item.label }}</option>
                        </select>
                      </div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-3"></div>
                      <div class="p-2 bd-highlight question-item-col-4"></div>
                    </div>

                    <div
                      class="d-flex flex-wrap question-item-row question-answer"
                      v-for="(answer, answerIndex) in question.answers"
                      :key="answerIndex"
                    >
                      <div class="p-2 bd-highlight question-item-col-1 text-center align-middle">
                        <input type="radio" name="is_correct_flg" v-model="answer.is_correct_flg" />
                      </div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-2">
                        <div class="col-wrapper">
                          <ValidationProvider
                            :name="$t('EDIT_SURVEY_COMPONENT_EN_QUESTION_ANSWER_FIELD', { number: answerIndex + 1 })"
                            :rules="getEnglishAnswerRules(answerIndex)"
                            v-if="!answer.is_other_flg"
                          >
                            <div :class="[{ 'is-duplicated-item': answer.isDuplicatedItem }]" slot-scope="{ errors }">
                              <input
                                type="text"
                                v-model="answer.text"
                                :placeholder="$t('EDIT_SURVEY_COMPONENT_EN_QUESTION_ANSWER_PLACEHOLDER', { number: answerIndex + 1 })"
                                class="form-control"
                                @input="onChangeAnswer()"
                              />
                              <span class="error-msg-wrap">
                                <span class="error-msg">{{ errors[0] }}</span>
                              </span>
                            </div>
                          </ValidationProvider>
                          <div class="form-control-plaintext" v-else>
                            {{ $t('EDIT_SURVEY_COMPONENT_OTHER_OPTION_TEXT') }}
                          </div>
                        </div>
                      </div>
                      <div class="p-2 bd-highlight question-item-col-center align-middle">
                        <CButton @click="removeOption(question, answer, answerIndex, $event)" class="btn-remove-question-answer">
                          <img src="@/assets/images/remove-question-option.svg" alt class="" />
                        </CButton>
                      </div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-3">
                        <div class="col-wrapper">
                          <ValidationProvider
                            :name="$t('EDIT_SURVEY_COMPONENT_JP_QUESTION_ANSWER_FIELD', { number: answerIndex + 1 })"
                            :rules="getEnglishAnswerRules(answer, answerIndex)"
                            v-if="!answer.is_other_flg"
                          >
                            <div :class="[{ 'is-duplicated-item': answer.isDuplicatedJpItem }]" slot-scope="{ errors }">
                              <input
                                type="text"
                                v-model="answer.text_ja"
                                :placeholder="$t('EDIT_SURVEY_COMPONENT_JP_QUESTION_ANSWER_PLACEHOLDER', { number: answerIndex + 1 })"
                                class="form-control"
                                @input="onChangeAnswer()"
                              />
                              <span class="error-msg-wrap">
                                <span class="error-msg">{{ errors[0] }}</span>
                              </span>
                            </div>
                          </ValidationProvider>
                          <div class="form-control-plaintext" v-else>
                            {{ $t('EDIT_SURVEY_COMPONENT_OTHER_OPTION_TEXT') }}
                          </div>
                        </div>
                      </div>
                      <div class="p-2 bd-highlight question-item-col-4"></div>
                    </div>

                    <div
                      class="d-flex question-item-row"
                      v-if="question.question_type === 'SINGLE_SELECTION' || question.question_type === 'MULTIPLE_SELECTIONS'"
                    >
                      <div class="p-2 bd-highlight question-item-col-1"></div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-2">
                        <button class="btn btn-link btn-add-answer" @click="addAnswer(question, $event)">
                          {{ $t('EDIT_SURVEY_COMPONENT_ADD_ANSWER_BUTTON') }}
                        </button>
                        <span v-if="isEnabledAddOtherOption(question)">
                          <span class="">
                            {{ $t('EDIT_SURVEY_COMPONENT_OR_TEXT') }}
                          </span>
                          <button class="btn btn-link btn-add-other-option" @click="addOtherOption(question, $event)">
                            {{ $t('EDIT_SURVEY_COMPONENT_ADD_OTHER_OPTION_BUTTON') }}
                          </button>
                        </span>
                      </div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-3"></div>
                      <div class="p-2 bd-highlight question-item-col-4"></div>
                    </div>
                    <div class="d-flex question-item-row" v-else>
                      <div class="p-2 bd-highlight question-item-col-1"></div>
                      <div class="p-2 flex-grow-1 bd-highlight question-item-col-2">
                        <div class="textbox-text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                          magna aliqua.
                        </div>
                      </div>
                      <div class="p-2 bd-highlight question-item-col-4"></div>
                    </div>
                  </div>
                </div>
              </CCardBody>
            </CCard>

            <CRow class="question-form-action">
              <CCol lg="12" md="12" class="text-right">
                <CButton @click="addQuestion()" class="btn btn-add-question">
                  {{ $t('EDIT_QUIZ_COMPONENT_ADD_QUESTION_BUTTON') }}
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>

        <StakingModal :linkTo="surveyListUrl" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import { Constants, env } from '@/constants';
import StakingModal from '@/components/StakingModal';
import dateTimeHelper from '@/utils/datetime-helper';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import StakingBadge from '@/components/StakingBadge';

const actionType = {
  Draft: 'draft',
  Publish: 'publish',
};

export default {
  name: 'EditSurvey',
  components: {
    VclList,
    StakingModal,
    DatePicker,
    StakingBadge,
  },
  data() {
    return {
      time1:null,
      surveyId: null,
      noId: null,
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      isAllowedCreate: false,
      warningLeavePage: false,
      isEnabledPublish: false,
      actionName: actionType.Draft,
      surveyListUrl: '/admin/setting/ms-point',
      dateTimePickerFormat: 'yyyy-MM-dd HH:mm',
      maxOfEnName: 24,
      maxOfJpName: 14,
      questionTypeList: [],
      membershipTypes: [
        { name: 'Silver', points: null },
        { name: 'Gold', points: null },
        { name: 'Platinum', points: null },
      ],
      survey: {
        name: '',
        name_ja: '',
        questions: [this.createNewQuestion()],
      },
      errorMessage: '',
      errorMessageSurveyDate: '',
      publishErrorMessage: '',
      hasDateTimeError: false,
      surveyTypeList: [],
      surveyStatusList: [],
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    duration() {
      return dateTimeHelper.getDurationTime(this.survey.start_date, this.survey.end_date);
    },
    getMembershipPointsRules() {
      if (this.actionName === actionType.Publish) {
        return 'required|min_value:1|max_value:100000000';
      }
      if (this.actionName === actionType.Draft) {
        return 'min_value:1|max_value:100000000';
      }

      return 'min_value:1';
    },
    getQuestionTitleRules() {
      return 'required|max:1000';
    },
  },
  async mounted() {
    const surveyId = this.$route.params.id;
    this.noId = this.$route.hash.replace('#', 'No.') + ' ';
    this.checkPermissions();
    await Promise.all([this.getQuestionsTypes()]);

    if (surveyId) {
      this.isEdit = true;
      this.surveyId = surveyId;
      await this.getQuizDetails(this.surveyId);
      this.isLoading = false;
    } else {
      this.isEdit = false;
      this.isEnabledPublish = true;
      this.isLoading = false;
    }
  },
  methods: {
    getEnglishAnswerRules(answer, answerIndex) {
      if (answer.is_other_flg) {
        return '';
      }

      return 'required|max:500';
    },
    isEnabledAddOtherOption(question) {
      return !question.answers.some((item) => item.is_other_flg);
    },
    createNewQuestion() {
      return {
        title: '',
        title_ja: '',
        question_type: 'SINGLE_SELECTION',
        answers: [
          {
            text: '',
            text_ja: '',
            is_correct_flg: false,
            is_other_flg: false,
          },
        ],
      };
    },
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'UPDATE_SURVEY') {
            this.isAllowedUpdating = true;
          }
          if (item.name === 'CREATE_SURVEY') {
            this.isAllowedCreate = true;
          }
        });
      }
    },
    async getQuestionsTypes() {
      try {
        const result = await this.$http.get(endpoints.getQuestionsTypes);
        this.questionTypeList = stringHelper.translateTextForDropdownList(result.data, 'QUESTION_TYPE');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('QUESTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getQuizDetails(id) {
      try {
        const res = await this.$http.get(endpoints.getQuizDetails(id));
        const survey = res.data.survey;
        const questions = res.data.questions;

        this.membershipTypes = [
          { name: 'Silver', points: survey.silver_membership_point },
          { name: 'Gold', points: survey.gold_membership_point },
          { name: 'Platinum', points: survey.platinum_membership_point },
        ];
        survey.start_date = moment(survey.start_date).toDate();
        survey.end_date = moment(survey.end_date).toDate();
        survey.questions = questions;
        survey.questions.forEach((question) => {
          question.answers = _.sortBy(question.answers, (item) => (item.is_other_flg ? 1 : 0));
        });

        this.survey = survey;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_QUIZ_COMPONENT_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onChangeAnswer() {
      setTimeout(() => {
        this.dectectChangeFn();
      }, 50);
    },
    dectectChangeFn: _.debounce(function (e) {
      this.fillDuplicateAnswers();
    }, 500),
    backToSurveyList() {
      this.$router.push(this.surveyListUrl);
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.surveyListUrl);
      }
    },
    removeQuestion(question, index) {
      this.survey.questions.splice(index, 1);
    },
    addQuestion() {
      this.survey.questions.push(this.createNewQuestion());
    },
    hidePublishErrorMessage() {
      this.publishErrorMessage = '';
    },
    onChangeQuestionType(question, $event) {
      if (question.question_type === 'OPEN_ENDED') {
        question.previousAnswers = question.answers;
        question.previousType = question.question_type;
        question.answers = [];

        return;
      }

      if (question.previousType === 'OPEN_ENDED') {
        question.answers = question.previousAnswers;
      }

      question.previousType = question.question_type;
    },
    addAnswer(question, event) {
      event.preventDefault();

      question.answers.push({
        text: '',
        text_ja: '',
        is_correct_flg: false,
        is_other_flg: false,
      });
      question.answers = _.sortBy(question.answers, (item) => (item.is_other_flg ? 1 : 0));
    },
    addOtherOption(question, event) {
      event.preventDefault();

      question.answers.push({
        text: '',
        text_ja: '',
        is_correct_flg: false,
        is_other_flg: true,
      });
    },
    removeOption(question, answer, answerIndex, event) {
      event.preventDefault();

      question.answers.splice(answerIndex, 1);
      this.dectectChangeFn();
    },
    getMappingQuestions() {
      const questions = (this.survey.questions || []).map((question) => {
        return {
          id: question.id,
          title: question.title,
          title_ja: question.title_ja,
          question_type: question.question_type,
          answers: (question.answers || []).map((answer) => {
            return {
              id: answer.id,
              text: answer.text,
              text_ja: answer.text_ja,
              is_correct_flg: answer.is_correct_flg || false,
              is_other_flg: answer.is_other_flg || false,
            };
          }),
        };
      });

      return questions;
    },
    async saveAsDraft() {
      if (this.surveyId) {
        return this.updateDraft();
      }

      const isValid = await this.$refs.form.validate();
      if (!isValid || !this.survey.questions.length) {
        return;
      }

      if (this.survey.isProcessing) {
        return;
      }

      this.survey.isProcessing = true;
      try {
        const points = this.membershipTypes.reduce((value, item) => {
          value[`${item.name.toLowerCase()}_membership_point`] = item.points;

          return value;
        }, {});

        const data = {
          name: this.survey.name,
          name_ja: this.survey.name_ja || null,
          start_date: moment(this.survey.start_date),
          end_date: moment(this.survey.end_date),
          type: 'SURVEY',
          ...points,
          questions: this.getMappingQuestions(),
        };
        const result = await this.$http.post(endpoints.createDraftSurvey, data);

        this.survey.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EDIT_QUIZ_COMPONENT_SAVE_DRAFT_QUIZ_SUCCESS_TITLE'),
          text: this.$t('EDIT_QUIZ_COMPONENT_SAVE_DRAFT_QUIZ_SUCCESS_TEXT', { quizTitle:this.noId +  data.name }),
        });

        this.backToSurveyList();
      } catch (err) {
        this.survey.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_QUIZ_COMPONENT_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async updateDraft() {
      if (this.actionName !== actionType.Draft) {
        this.actionName = actionType.Draft;
        setTimeout(() => {
          this.updateDraft();
        }, 100);

        return;
      }

      const isValid = await this.$refs.form.validate();
      if (!isValid || !this.survey.questions.length) {
        return;
      }

      if (this.survey.isProcessing) {
        return;
      }

      this.survey.isProcessing = true;
      try {
        const points = this.membershipTypes.reduce((value, item) => {
          value[`${item.name.toLowerCase()}_membership_point`] = item.points;

          return value;
        }, {});
        const data = {
          action_name: this.actionName,
          name: this.survey.name,
          name_ja: this.survey.name_ja || null,
          start_date: moment(this.survey.start_date),
          end_date: moment(this.survey.end_date),
          ...points,
          questions: this.getMappingQuestions(),
        };
        const result = await this.$http.put(endpoints.updateSurvey(this.surveyId), data);

        this.survey.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EDIT_QUIZ_COMPONENT_SAVE_DRAFT_QUIZ_SUCCESS_TITLE'),
          text: this.$t('EDIT_QUIZ_COMPONENT_SAVE_DRAFT_QUIZ_SUCCESS_TEXT', { quizTitle:this.noId +  data.name }),
        });

        this.backToSurveyList();
      } catch (err) {
        this.survey.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_QUIZ_COMPONENT_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async publish() {
      if (this.actionName !== actionType.Publish) {
        this.actionName = actionType.Publish;
        setTimeout(() => {
          this.publish();
        }, 100);

        return;
      }

      const isValid = await this.$refs.form.validate();
      if (!isValid || !this.survey.questions.length) {
        return;
      }
      if (this.survey.isProcessing) {
        return;
      }

      this.survey.isProcessing = true;
      try {
        const points = this.membershipTypes.reduce((value, item) => {
          value[`${item.name.toLowerCase()}_membership_point`] = item.points;

          return value;
        }, {});
        const data = {
          action_name: this.actionName,
          name: this.survey.name,
          name_ja: this.survey.name_ja || null,
          start_date: moment(this.survey.start_date),
          end_date: moment(this.survey.end_date),
          ...points,
          questions: this.getMappingQuestions(),
        };
        const result = await this.$http.put(endpoints.updateSurvey(this.surveyId), data);

        this.survey.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EDIT_QUIZ_COMPONENT_SAVE_DRAFT_QUIZ_SUCCESS_TITLE'),
          text: this.$t('EDIT_QUIZ_COMPONENT_PUBLISH_QUIZ_SUCCESS_TEXT', { quizTitle:this.noId +  data.name }),
        });

        this.publishErrorMessage = '';
        this.hasDateTimeError = false;
        this.backToSurveyList();
      } catch (err) {
        this.hasDateTimeError = false;
        this.survey.isProcessing = false;

        if (err.code === 'THERE_ARE_ACTIVITY_DURING_THIS_TIME') {
          this.publishErrorMessage = this.$t('EDIT_QUIZ_COMPONENT_CAN_NOT_PUBLISH_QUIZ_OVERLAP_DATE_TEXT');
          this.hasDateTimeError = true;

          return;
        }

        if (err.code === 'TO_DATE_MUST_BE_GREATER_THAN_OR_EQUAL_FROM_DATE') {
          this.publishErrorMessage = this.$t('EDIT_QUIZ_COMPONENT_CAN_NOT_PUBLISH_QUIZ_TEXT');
          this.hasDateTimeError = true;

          return;
        }

        if (err.code === 'START_DATE_MUST_BE_GREATER_THAN_OR_EQUAL_TODAY') {
          this.publishErrorMessage = this.$t('EDIT_QUIZ_COMPONENT_CAN_NOT_PUBLISH_QUIZ_INVALID_FUTURE_DATE_TEXT');
          this.hasDateTimeError = true;

          return;
        }

        if (err.code === 'THERE_ARE_TWO_OVERLAPPING_FIELD') {
          this.publishErrorMessage = this.$t('EDIT_QUIZ_COMPONENT_CAN_NOT_PUBLISH_SAME_ANSWER_TEXT');
          this.fillDuplicateAnswers(true);

          return;
        }

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_QUIZ_COMPONENT_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    fillDuplicateAnswers(hasError) {
      this.survey.questions.forEach((question) => {
        const answers = question.answers;
        if (!answers || !answers.length) {
          return;
        }

        const textArray = [];
        answers.forEach((answer) => {
          let text = _.trim(answer.text);
          if (text) {
            textArray.push(text);
          }

          text = _.trim(answer.text_ja);
          if (text) {
            textArray.push(text);
          }
        });

        answers.forEach((answer) => {
          let text = _.trim(answer.text);
          if (text) {
            const result = textArray.filter((item) => item === text);
            answer.isDuplicatedItem = result.length >= 2;
            hasError = hasError || answer.isDuplicatedItem;
          }

          text = _.trim(answer.text_ja);
          if (text) {
            const result = textArray.filter((item) => item === text);
            answer.isDuplicatedJpItem = result.length >= 2;
            hasError = hasError || answer.isDuplicatedItem;
          }
        });
      });

      const errorText = this.$t('EDIT_QUIZ_COMPONENT_CAN_NOT_PUBLISH_SAME_ANSWER_TEXT');
      if (hasError) {
        this.publishErrorMessage = errorText;
      } else {
        if (this.publishErrorMessage === errorText) {
          this.publishErrorMessage = '';
        }
      }
    },
  },
};
</script>
<style lang="scss">
.edit-survey-container {
  &.wrapper {
    padding: 0 23px;
  }

  .card.edit-quiz-info-card {
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #dadde1;
    border-radius: 4px;

    > .card-header {
      border-bottom: none;
      padding: 1rem 1.25rem;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
      .btn-publish {
        font-size: 14px;
        line-height: 14px;
        height: 36px;
        color: #fff;
        border: none;
        min-width: 200px;
        background: #3b4b7e;
        border: 1px solid #3b4b7e;
        box-sizing: border-box;
        border-radius: 4px;

        &.disable {
          background: rgba(255, 255, 255, 0.4);
        }
      }

      .btn-save-as-draft {
        font-size: 14px;
        line-height: 14px;
        height: 36px;
        color: #3b4b7e;
        border: none;
        min-width: 200px;
        background: #ffffff;
        border: 1px solid #3b4b7e;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    .tab-content {
      padding: 10px 10px 0 10px;
    }
    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
      .survey-type {
        padding-top: 10px;
      }

      .mx-datepicker {
        display: block;
        width: 100%;
      }

      &.is-invalid {
        .mx-input {
          border: 1px solid #ff5252;
        }
      }
    }

    .group-price {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0;
      input {
        width: calc(100% - 55px);
        text-align: right;
      }
      span.currency-name {
        width: 50px;
        font-size: 14px;
        font-weight: 300;
        color: #4d5666;
        line-height: 35px;
        margin: 0;
        padding-left: 5px;
        text-align: right;
      }
      .error-msg-wrap {
        width: 100%;
      }
    }

    .form-paid-membership {
      .table-price {
        border: 1px solid #dadada;
        margin-bottom: 0;

        thead th {
          vertical-align: bottom;
          border-bottom: none;
        }

        td {
          padding: 0.2rem 0.75rem;
          vertical-align: middle;
          border-top: none;
        }

        .form-group {
          input {
            font-size: 14px;
            font-weight: 500;
            color: #3a4a7f;
          }
        }
        .btn-switch-wrapper {
          height: 34px;
          padding-top: 8px;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }

    .error-message {
      color: red;
    }
  }

  .card.question-card {
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #dadde1;
    border-radius: 4px;

    .card-body {
      .question-item {
        display: block;
        width: 100%;

        .question-item-row {
          > .question-item-col-1,
          > .question-item-col-4 {
            width: 60px;
            &.align-middle {
              line-height: 41px;
            }
          }

          > .question-item-col-center {
            width: 40px;
            text-align: center;
            &.align-middle {
              line-height: 30px;
            }
          }

          > .question-item-col-2,
          > .question-item-col-3 {
            width: 200px;
          }

          .btn-add-answer,
          .btn-add-other-option {
            font-size: 14px;
            line-height: 24px;
            text-decoration-line: underline;
            color: #5f55e7;
          }

          .question-type-select {
            width: 200px;
          }

          .textbox-text {
            font-size: 14px;
            line-height: 24px;
            color: #3a4a7f;
          }

          .correct-answer-label {
            font-size: 12px;
            line-height: 12px;
            text-align: left;
            color: #8a93a2;
          }
          .question-answer-note-label {
            font-size: 14px;
            line-height: 41px;
            color: #8a93a2;
          }

          .btn-remove-question-answer {
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 0;
            img {
              width: 20px;
              height: auto;
            }
          }

          .btn-remove-question {
            font-size: 14px;
            font-weight: 400;
            color: #0505a5;
            outline: none;
            padding-top: 0;
            padding-bottom: 0;
            outline: none;
          }

          .is-duplicated-item {
            .form-control {
              border: 1px solid #ff5252;
            }
          }

          .hand {
            cursor: pointer;
          }
        }

        > .question-item-footer {
          background: yellow;
        }
      }
    }
  }

  .question-form-action {
    padding: 0.5rem 0 1.25rem;

    .btn-add-question {
      font-size: 14px;
      line-height: 14px;
      height: 36px;
      color: #3b4b7e;
      border: none;
      min-width: 200px;
      background: #ffffff;
      border: 1px solid #3b4b7e;
      box-sizing: border-box;
      border-radius: 18px;
    }
  }
}
</style>
